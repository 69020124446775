.container {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 10px;
}

img {
  width: 260px;
  padding: 10px;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  width: 50%;
}

.col-center {
  flex: 1;
  text-align: center;
}

.left-col {
  width: 320px;
}

.middle-col {
  width: 170px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #22b0bf;
  font-size: calc(10px + 1.8vmin);
  color: white;
  margin-bottom: 15px;
  padding-left: 12px;
}

a:visited {
  color: white;
}

a {
  color: white;
}

.header-line {
  flex-grow: 1;
  background-color: grey;
  color: azure;
}

.input-line {
  margin: 6px;
}

label {
  margin: 6px;
}

.calculated-result {
  text-align: right;
}
